<template>
  <v-row>
    <v-timeline dense class="timeline-bar">
      <div v-for="(image, i) in images" :key="i">
        <v-timeline-item color="#193154">
          <div class="titre">{{ image.title }}</div>
          <div class="subtitle">({{ image.subtitle }})</div>
        </v-timeline-item>
        <v-row class="pic">
          <v-col v-for="n in image.number" :key="n" class="d-flex" cols="4">
            <v-img
              :src="getImage(image, n)"
              aspect-ratio="1"
              class="grey lighten-2 pic-ind mb-5"
              width="100"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </div>
    </v-timeline>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          name: "parachute-octobre",
          title: "Saut en parachute",
          subtitle: "Octobre 2021",
          number: 9,
        },
        {
          name: "promo-2022",
          title: "Équipe promo 2022",
          subtitle: "2022",
          number: 1,
        },
      ],
    };
  },
  methods: {
    getImage(image, n) {

      return require("../assets/galerie/" +
        image.name +
        "-" +
        String(n) +
        ".jpg");
    },
  },
};
</script>

<style>
.timeline-bar {
  margin-left: 100px;
}
.titre {
  font-size: 30px;
  font-weight: bolder;
  color: #193154;
}
.subtitle {
  font-size: 20px;
  font-weight: lighter;
  font-style: italic;
  color: #193154;
}
.pic {
  margin-left: 100px;
}

.pic-ind:hover {
  cursor: pointer;
}

.picsize {
  size: 60px;
}
</style>